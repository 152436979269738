import React, { useState, useEffect, useRef } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Thumbs, Pagination } from "swiper/core"
import Carousel, { Modal, ModalGateway } from "react-images"

SwiperCore.use([Thumbs, Pagination])
const ProductThumbnailsData = gallery => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  const paramsTrack = {
    slidesPerView: 3,
    watchOverflow: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    direction: "vertical",
    spaceBetween: 10,
  }
  const params = {
    watchOverflow: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    preventInteractionOnTransition: true,
    spaceBetween: 10,
    onInit: () => {
      window.dispatchEvent(new Event("resize"))
    },
  }
  var galleryArr = gallery.gallery

  const imgArr = []
  galleryArr.map(item => {
    imgArr.push({ source: item.imagePath })
  })

  const [customData, setCustomData] = useState(galleryArr)
  const [loaded, setLoaded] = useState(false)
  const imgElement = useRef(null)

  useEffect(() => {
    setCustomData(galleryArr)
  }, [galleryArr])

  return (
    <>
      <div className="thumbnail-wrap">
        <Swiper
          className="thumbnail-track"
          {...paramsTrack}
          onSwiper={setThumbsSwiper}
        >
          {galleryArr.map((galleryImage, idx) => (
            <SwiperSlide key={`gallery-thumb-${idx}`}>
              <div className={`img-wrap pointer ${galleryImage.imagePosition}`}>
                <img
                  src={galleryImage.imagePath}
                  alt={galleryImage.caption}
                ></img>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        <Swiper
          className="thumbnail-big"
          pagination={{ clickable: true }}
          {...params}
          thumbs={{ swiper: thumbsSwiper }}
        >
          {galleryArr.map((galleryImage, idx) => (
            <SwiperSlide
              key={`gallery-image-${idx}`}
              className={`${galleryImage.imagePosition}`}
            >
              <a
                href={galleryImage.imagePath}
                className="d-block thumbnail-wrap-item pointer"
                data-fancybox="gallery"
                data-width={
                  loaded && imgElement.current && imgElement.current.src.slice(-3) === "svg"
                    ? imgElement.current.naturalWidth * 9
                    : ""
                }
                data-height={
                  loaded && imgElement.current && imgElement.current.src.slice(-3) === "svg"
                    ? imgElement.current.naturalHeight * 9
                    : ""
                }
              >
                <img
                  src={galleryImage.imagePath}
                  alt={galleryImage.caption}
                  ref={imgElement}
                  onLoad={() => setLoaded(true)}
                ></img>
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  )
}

export default ProductThumbnailsData
